<mat-sidenav-container autosize="true" hasBackdrop="false">
   @if (!!(authService.currentUser | async)) {
      <mat-sidenav [mode]="(belowMd | async).matches ? 'above' : 'side'" opened="true" [fixedInViewport]="true">
         <app-nav-menu></app-nav-menu>
      </mat-sidenav>
   }

   <mat-sidenav-content>
      @if (!!(authService.currentUser | async)) {
         <mat-toolbar [class.invalid-license]="!license.isLicenseValid || license.isLicenseExpired">
            @if ((belowMd | async).matches) {
               <button mat-icon-button (click)="sidenavContainer.open()">
                  <img src="assets/images/logos/logo_alleo_a.svg" />
               </button>
            }
            <app-toolbar></app-toolbar>
         </mat-toolbar>
      }

      <router-outlet></router-outlet>
   </mat-sidenav-content>
</mat-sidenav-container>
