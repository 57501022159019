/* eslint-disable no-bitwise */
import {
   BoardAccessLevel,
   BoardClassification,
   BoardType,
   CapabilityTarget,
   CapabilityType,
   ConfigurationSection,
   ConfigurationTarget,
   FileContainerScope,
   FileScope,
   FileSystemContainerPermission,
   FileSystemNodeClassifier,
   InviteType,
   LogLevelStr,
   Role,
   ThirdPartyAuthProvider,
   ThirdPartyConnectionScope,
   ThirdPartyConnectionType,
   ThirdPartyConnectMode,
   ThirdPartyScopeType,
   TranslationSourceType
} from './enums';

export enum ApiResponseErrorCode {
   ModelStateInvalid = 'ModelStateInvalid',
   UserNotFound = 'UserNotFound',
   UserAccountDisabled = 'UserAccountDisabled',
   ThirdPartyConnectionProviderKeyNotFound = 'ThirdPartyConnectionProviderKeyNotFound',
   StorageNodeSourceDeleted = 'StorageNodeSourceDeleted',
   StorageNodeNotFound = 'StorageNodeNotFound',
   BoardAccessDenied = 'BoardAccessDenied',
   EntityNotFound = 'EntityNotFound',
   BearerTokenUserNotFound = 'BearerTokenUserNotFound',
   EntityLimit = 'EntityLimit'
}

export enum ErrorCodesEntityNotFound {
   InteractiveAgentRegistration = 'InteractiveAgentRegistration'
}
export class PagedResultDto<T> {
   results: T[];
   count: number;
   totalCount?: number;
}

export class OrgListDto {
   public Orgs: OrgDto[] = [];
   public total_count: number;
}

export class Org {
   public orgId: string;
   public name: string;
   public shortName: string;
   public domain: string;
   public description: string;
   public groupCount: number;
   public userCount: number;
   public pendingInviteCount: number;
   public lastActivityAt: Date;
   public autoAssignEmailDomains: string;
   public storageUsed: number;
   public defaultNewUserPlanId?: string;
   public expirationDate: Date;
   public limits?: OrgLimits;
   public settings?: OrgSettings;
   public changeToUserPlanId?: string;
}
export class OrgLimits {
   public maxUserCount?: number;
   public maxRoomCount?: number;
}

export class OrgSettings {
   public isPendoEnabled?: boolean;
}

export class OrgInvites {
   public id: string;
   public organizationId: string;
   public email: string;
   public createdAt: Date;
   public expiresAt: Date;
}

export class orglist {
   public name: string;
   public orgId: string;
}

export class OrgTableDto {
   orgs: Org[];
   total_count: number;
}

export class OrgDto {
   organizationId: string;
   name: string;
   domain: string;
   description: string;
}

export class LoginProviderRequestDto {
   public rememberMe?: boolean;
   public provider: ThirdPartyAuthProvider;
   public inviteToken?: string | null;
}

export class LoginResponseDto {
   public authToken: AuthTokenDto;
   public user: UserDto;
   public isTwoFactorAuthorizationRequired: boolean;
}

export class LoginErrorDataDto {
   public redirect?: LoginRedirect;
}

export class LoginRedirect {
   public targetUrl: string;
}

export class AuthTokenDto {
   public expiresAt: Date;
   public expiresInMinutes: number;
   public token: string;
}

export class UserLoginDto {
   public username: string;
   public password: string;
}

export class UserRegistrationDto {
   public firstname: string;
   public lastname: string;
   public email: string;
   public password: string;
   public token: string;
}

export class NewUserDto {
   public firstname: string;
   public lastname: string;
   public email: string;
   public password: string;
   public organizationId: string;
}

export class ChangeUserRolePutRequestDto {
   public roles: Role[];
}

export class UserDto {
   public id: string;
   public email: string;
   public imageUrl: string;
   public lastActivityAt: Date;
   public name: string;
   public organization: OrganizationDto;
   public firstName: string;
   public lastName: string;
}

export class UserTableDto {
   public users: UserTableItemDto[] = [];
   public total_count: number;
}

export class userTableItemRoleDto {
   public roleId: string;
   public roleName: string;
}

export class UserTableItemDto {
   public userId: string;
   public firstName: string;
   public lastName: string;
   public email: string;
   public username: string;
   public organizationId: string;
   public accountDisabled: boolean;
   public emailconfirmed: boolean;
   public createdAt: Date;
   public lastActivity: Date;
   public roles: userTableItemRoleDto[] = [];
   public groups: GroupDto[] = [];
   public boardCount: number;
   public organizationName: string;
   public storageUsed: number;
   public referrals: number;
   public plan: UserPlanDto;
}

// group dtos

export class GroupTableDto {
   public groups: GroupDto[] = [];
   public total_count: number;
}

export class UserGroupDto {
   public userId: string;
   public groupId: string;
}

export class GroupDto {
   public id: string;
   public name: string;
   public organizationId: string;
   public userCount: number;
}

export class GroupUser {
   public groupId: string;
   public groupName: string;
   public userId: string;
   public userEmail: string;
   public userDisplayName: string;
}

export class GroupMembersPutRequestDto {
   public userIds: string[];
}

// Invite-share dtos

export class inviteToOrgLinkShare {
   public firstName: string;
   public lastName: string;
   public email: string;
   public orgId: string;
}

export class LinkShareDto {
   public linkShareId: number;
   public boardAccessType: BoardAccessType;
   public resourceType: ResourceType;
   public resourceId: string;
   public baseURL: string;
   public token: string;
   public invitedEmails: string[] = [];
   public createdOn: Date;
   public linkExpiresOn: Date;
   public isEnabled: boolean;
   public isOneTimeUse: boolean;
   public createdByUserId: string;
}

export enum ResourceType {
   Board = 1,
   OrgInvite = 2
}

export enum BoardAccessType {
   BoardPublicRead = 1,
   BoardPrivatReadInvite = 2,
   BoardOrgRead = 3,
   BoardOrgMemberInviteRead = 4
}

// Meeting Room dtos

export class MinimalFileDto {
   public id: string;
   public type: string;
   public url: string;
}

export class BoardTemplateFileDto {
   public id: string;
   public name: string;
   public description?: string;
   public fileId?: string;
   public thumbnailContentPreviewFile?: MinimalFileDto;
   public userId?: string;
   public organizationId?: string;
}

export class BoardTemplateFilePatchRequestDto {
   public name: string;
   public description: string;
}

export class BoardTemplateFilePutRequestDto {
   public name: string;
   public description: string;
}

export class CalendarDto {
   public id: string;
   public name: string;
   public sourceLocation: string;
   public thirdPartyConnection: ThirdPartyConnectionDto;
}
export enum MeetingHubRoomSourceType {
   AlleoBoard = 'AlleoBoard',
   // TODO: Remove HapticBoard once all clients are updated
   HapticBoard = 'HapticBoard'
}

export class MeetingHubRoomSourceDto {
   public type: MeetingHubRoomSourceType;
   public id: string;
   public data?: any;
}

export class MeetingRoom {
   public id: string;
   public name: string;
   public shortCode?: string;
   public deviceType: string;
   public experienceType: string;
   public licenseKey: string;
   public organizationId: string;
   public resourceId: string;
   public isOnline: boolean;
   public backgroundMedias: MinimalFileDto[];
   public logoImageFile: MinimalFileDto;
   public backgroundNodeId: string;
   public settings: MeetingRoomSettingsData;
   public calendars: CalendarDto[];
   public accountDisabled: boolean;
   public tags: string[];
   public clientInfo?: MeetingRoomClientInfoDto;
   public lastActivityAt?: Date;
   public sources?: MeetingHubRoomSourceDto[];
}

export class MeetingRoomClientInfoDto {
   public name: string;
   public version: string;
   public browser?: Browser;
}

export class MeetingRoomSettingsData {
   public screensaverUrl?: string;
   public canRunInBackgroundMinimized?: boolean;
   public ignorePresentationModes?: boolean;
   public forceViewerPermissions?: boolean;
   public doNotJoinCalls?: boolean;
   public requiresAdminToOverrideScreensaver?: boolean;
   public allowAdminInUseNavigation?: boolean;
   public logLevel?: LogLevelStr;
   public whitelistedProxyCallEndpoints?: string[];
}

export class MeetingRoomPatchRequestDto {
   public name: string;
   public resourceId: string;
   public deviceType: string;
   public experienceType: string;
   public backgroundNodeId: string;
   public logoImageFileId: string;
   public settings: MeetingRoomSettingsData;
   public externalCalendarUrl?: string;
   public calendarConnectionId?: string;
   public calendarConnectionScope?: string;
   public tags: string[];
}

export class MeetingRoomBoardTemplateFilesPutRequestDto {
   public boardTemplateFileIds: string[];
}

export class MeetingRoomCreateRequestDto {
   public name: string;
   public deviceType: string;
   public experienceType: string;
   public organizationId: string;
   public backgroundNodeId: string;
   public logoImageFileId: string;
   public timeZone: string;
}

export class MeetingRoomsListResponseDto {
   results: MeetingRoom[];
   totalCount: number;
}

export class MeetingRoomStatusDto {
   public id: string;
   public sources?: MeetingHubRoomSourceDto[];
   public lastHeartBeatAt?: Date;
   public isOnline: boolean;
   public shortCode?: string;
   public deviceData?: DeviceData;
}

export class DeviceData {
   public userAgent: string;
   public os: OperatingSystem;
   public browser: Browser;
   public device: Device;
   public locale: string;
   public screenResolution: string;
   public hasTouch: boolean;
   public videoInputs: MediaDeviceInfo[];
   public audioInputs: MediaDeviceInfo[];
   public connection: ConnectionInfo;
   public memory: MemoryInfo;
}

export class ConnectionInfo {
   public type: string;
   public effectiveType: string;
   public downlink: number;
   public downlinkMax: number;
   public rtt: number;
   public saveData: boolean;
}

export class MemoryInfo {
   totalJSHeapSize: number;
   usedJSHeapSize: number;
   jsHeapSizeLimit: number;
}

export class OperatingSystem {
   public name: string;
   public version: string;
}

export class Browser {
   public name: string;
   public version: string;
}

export class Device {
   public model: string;
   public type: string;
   public vendor: string;
}

export class StorageNodeCreatedResponseDto {
   public node: FileSystemNodeDto;
}

export class StorageNodeMetaUpdateRequestDto {
   public name: string;
   public description: string;
   public tags: string[];
}

export class StorageNodeMoveRequestDto {
   public targetFolder: string;
   public targetContainerId?: string;
}

export enum StorageNodeSearchTypeFlags {
   SearchContainsInName = 1 << 0,
   SearchContainsInTags = 1 << 1,
   SearchContainsInContent = 1 << 2
}

export enum StorageNodeTypeFlags {
   File = 1 << 0,
   Folder = 1 << 1
}

export class StorageNodesListRequestDto {
   public boardId?: string;
   public folder?: string;
   public nodeType: StorageNodeTypeFlags;
   public search?: StorageNodeSearchQueryDto;
   public classifier?: FileSystemNodeClassifier;
   public includeSystemTypes?: boolean;
   public userId?: string;
}
export class StorageNodeSearchQueryDto {
   public search: string;
   public type: StorageNodeSearchTypeFlags;
}

export class StorageNodeFolderCreateRequestDto {
   public name: string;
   public folderPath: string;
}

export class StorageNodeFileCreateRequestDto {
   public name: string;
   public folderPath?: string;
   public classifier: FileSystemNodeClassifier;
}

export class FileSystemContainerDto {
   public id: string;
   public scopeId: string;
   public scope: FileContainerScope;
   public permission: FileSystemContainerPermission;
}

export class FilePartDto {
   public url: string;
}

export class FileSystemNodeDto {
   public id: string;
   public name: string;
   public nodeType: string;
   public path: string;
   public createdBy: MinimalUserDto;
   public lastModifiedBy: MinimalUserDto;
   public container: FileSystemContainerDto;
   public createdAt: Date;
   public meta: FileMeta;
   public parts: Record<string, FilePartDto>;
   public classifier: FileSystemNodeClassifier;
   public size: number;
}

export class FileMeta {
   public size: number;
   public extension: string;
   public mimeType: string;
   public originalName: string;
}

export class FileCreateRequestDto {
   public boardId: string;
   public externalFileUrl?: string;
   public directory?: string;
   public scope: FileScope;
   public classifier: FileSystemNodeClassifier;
   public organizationId?: string;
}

export class FileUpdateRequestDto {
   public id: string;
}

export class MinimalUserDto {
   public id: string;
   public name: string;
   public organization?: OrganizationDto;
}

export class OrganizationDto {
   public id: string;
   public name: string;
}

export class FileDto {
   public id: string;
   public url: string;
}

export class ConfigurationDto {
   public section: ConfigurationSection;
   public key: string;
   public id: string;
}

export class ConfigurationSetDto {
   public id: string;
   public configurationValues: ConfigurationValueDto[];
}

export class ConfigurationValueDto {
   public configuration: ConfigurationDto;
   public configurationSet: ConfigurationSetDto;
   public value?: any | ConfigurationData | DrawingColorsConfigurationData;
   public valueSchema?: any;
}

export class ConfigurationStructureDto {
   public byTarget: Record<ConfigurationTarget, ConfigurationValueDto[]>;
   public computed: ConfigurationValueDto[];
}

export class ConfigurationData {
   public type: string;
}

export class DrawingColorsConfigurationData extends ConfigurationData {
   public colors: ColorDefinition[];
}

export class ColorDefinition {
   public hex: string;
   public id: string;
   public label: string;
}

export class ConfigurationSettingPutRequestDto {
   public value: any;
}

export class ConfigurationSetCreateRequestDto {
   public targetId?: string;
   public target: ConfigurationTarget;
}

export class ConfigurationAppConfigurationDto {
   public section: ConfigurationSection;
   public key: string;
   public isHidden: boolean;
   public helpText?: string;
}

export class CapabilityAppConfigurationDto {
   public type: CapabilityType;
   public key: string;
   public isHidden: boolean;
   public helpText?: string;
}

export class CapabilityDto {
   public key: string;
   public type: CapabilityType;
   public setting: any;
   public isEnabled: boolean;
   public usage: any;
   public id: string;
   public condition?: CapabilityCondition;
}

export class CapabilitySetDto {
   public id: string;
   public capabilitySettings: CapabilitySettingDto[];
}

export class CapabilityCondition {
   public rules: any;
}

export class CapabilitySettingDto {
   public capability: CapabilityDto;
   public capabilitySet: CapabilitySetDto;
   public value: any;
   public isEnabled: boolean;
   public condition?: CapabilityCondition;
}

export class CapabilityStructureDto {
   public byTarget: Record<CapabilityTarget, CapabilitySettingDto[]>;
   public computed: CapabilitySettingDto[];
}

export class CapabilitySettingPutRequestDto {
   public capabilityId: string;
   public isEnabled: boolean;
   public value?: any;
   public condition?: CapabilityCondition;
}

export class CapabilitySetCreateRequestDto {
   public targetId?: string;
   public target: CapabilityTarget;
}

export class UserPlanDto {
   public capabilitySet: CapabilitySetDto;
   public name: string;
   public id: string;
   public isDefault: boolean;
   public changeToPlanAfterDays?: number;
   public changeToPlanId: string;
}

export class UserPlanPatchDto {
   plan: UserPlanDto;
   list: UserPlanDto[];
}

export class UserPlanCreateRequestDto {
   public name: string;
   public changeToPlanAfterDays?: number;
   public changeToPlanId?: string;
}

export class UserPlanPatchRequestDto {
   public name: string;
   public changeToPlanAfterDays?: number;
   public changeToPlanId?: string;
}

export class UserPatchPlanRequestDto {
   public planId: string;
}

export class UserPatchRequestDto {
   public firstName: string;
   public lastName: string;
}

export class LicenseDto {
   public isLicenseValid: boolean;
   public isLicenseExpired: boolean;
   public licensedLocationName: string;
   public expirationDate: Date;
}

export class PartnerDto {
   public id: string;
   public name: string;
   public subdomain: string;
   public theme: ThemeDto;
   public language: LanguageDto;
}

export class PartnerCreateRequestDto {
   public name: string;
   public subdomain: string;
   public languageId?: string;
}

export class PartnerPatchRequestDto {
   public name: string;
   public subdomain: string;
   public languageId?: string;
}

export class PartnerOrganizationsPutRequestDto {
   public organizationIds?: string[];
}

export enum AppTheme {
   // Legacy = 'Legacy', obsolete
   Dynamic = 'Dynamic',
   Alleo = 'Alleo'
}

export class ThemeDto {
   public id: string;
   public themeIdentifier: AppTheme;
   public authTheme: AuthThemeDto;
   public appTheme: AppThemeDto;
   public components?: ComponentStyleData[];
}

export class AppThemeDto {
   public logoImageFile?: MinimalFileDto;
   public colors?: ThemeColorsData;
}

export class ThemeColorsData {
   public primary: Record<string, string>; // {50: '#ffaaff' ... 'contrast-50': '#000000' ...}
   public accent: Record<string, string>; // {50: '#ffaaff' ... 'contrast-50': '#000000' ...}
   public warn: Record<string, string>; // {50: '#ffaaff' ... 'contrast-50': '#000000' ...}
   public background: Record<string, string>; // {50: '#ffaaff' ... 'contrast-50': '#000000' ...}
}

export class AuthThemeDto {
   public logoImageFile?: MinimalFileDto;
   public backgroundImageFile?: MinimalFileDto;
   public primaryColor?: string;
   public secondaryColor?: string;
}

export class ComponentStyleData {
   public identifier: string;
   public theme: string;
}

export class InviteCreateRequestDto {
   public allowedTargetDomains?: string;
   public type: InviteType;
}

export class InvitePatchRequestDto {
   public isEnabled?: boolean;
}

export class InviteDto {
   public id: string;
   public url: string;
   public type: InviteType;
   public isEnabled: boolean;
   public userCount: number;
   public allowedTargetDomains: string;
}

export class ThemePutRequestDto {
   public themeIdentifier: AppTheme;
   public appTheme?: AppThemePatchDto;
   public authTheme?: AuthThemePatchDto;
   public components?: ComponentStyleData[];
}

export class AppThemePatchDto {
   public logoImageFileId?: string;
   public colors: ThemeColorsData;
}

export class AuthThemePatchDto {
   public backgroundImageFileId?: string;
   public logoImageFileId?: string;
   public primaryColor?: string;
   public secondaryColor?: string;
}

export class LanguageDto {
   public id: string;
   public name: string;
   public iso: string;
   public nativeName: string;
   public organizationId?: string;
   public parentId?: string;
}

export class TranslationDto {
   public createdAt: Date;
   public id: string;
   public language: LanguageDto;
   public lastModifiedAt: Date;
   public unit: TranslationUnitDto;
   public value: string;
}

export class TranslationUnitTranslationsDto {
   public referenceTranslation: TranslationDto;
   public translation: TranslationDto;
   public unit: TranslationUnitDto;
}

export class TranslationUnitDto {
   public createdAt: Date;
   public id: string;
   public label: string;
   public lastModifiedAt: Date;
   public source: string;
   public sourceType: TranslationSourceType;
}

export class LanguageCreateRequestDto {
   public name: string;
   public iso: string;
   public nativeName: string;
   public organizationId?: string;
   public parentId?: string;
   public isEnabled: boolean;
}

export class TranslationPutRequestDto {
   public unitId: string;
   public value: string;
}

export class LanguagePatchRequestDto {
   public isEnabled?: boolean;
}

export class LocalizationImportRequestDto {
   public xliff?: string;
   public json?: any;
   public sourceType: TranslationSourceType;
}

export class LanguageImportRequestDto {
   public json?: any;
   public sourceType: TranslationSourceType;
}

export class ThirdPartyConnectionDto {
   public accessToken: string;
   public accessTokenExpiresAt?: Date;
   public isAccessTokenExpired: boolean;
   public id: string;
   public isConnected: boolean;
   public displayName: string;
   public provider: ThirdPartyAuthProvider;
   public providerKey: string;
   public scopes: string;
   public scope: ThirdPartyConnectionScope;
   public type: ThirdPartyConnectionType;
   public connectMode: ThirdPartyConnectMode;
   public externalUserEmail: string;
   public providerName: string;
}

export class ThirdPartyProviderConnectRequestDto {
   public mode: ThirdPartyConnectMode;
   public connectionType: ThirdPartyConnectionType;
   public scope: ThirdPartyConnectionScope;
   public isOrganizationConnection: boolean;
}

export class ThirdPartyConnectionDisconnectRequestDto {
   public id: string;
}

export class ThirdPartyCalendarDto {
   public id: string;
   public checksum: string;
   public name: string;
   public ownerMail: string;
   public ownerName: string;
}

export class OrganizationThirdPartyConnectionDto {
   public id: string;
   public connection: ThirdPartyConnectionDto;
   public organizationId: string;
   public scopeId: string;
   public scope: ThirdPartyScopeType;
}

export class BoardsTemplatesListResponseDto extends PagedResultDto<BoardsTemplatesListBoardDto> {}

export class BoardsTemplatesListBoardDto {
   public accessLevel: BoardAccessLevel;
   public classification: BoardClassification;
   public createdAt: Date;
   public description: string;
   public orphanId: string;
   public id: string;
   public name: string;
   public type: BoardType;
   public thumbnailContentPreviewFile?: MinimalFileDto;
   public templateBoardReferenceId: string;
}

export class BoardsTemplatesListRequestDto {
   public accessLevel?: BoardAccessLevel;
   public includeFeatured?: boolean;
   public includePublic?: boolean;
   public onlyFeatured?: boolean;
}

export enum GuideType {
   DriverJS = 'driver.js'
}

export enum UserType {
   User = 'User',
   MeetingRoom = 'MeetingRoom',
   Guest = 'Guest',
   Temporary = 'Temporary',
   System = 'System'
}

export enum BoardHubUserType {
   Undetermined = 'Undetermined',
   User = 'User',
   Guest = 'Guest'
}

export enum GuideScope {
   Global = 'Global',
   Partner = 'Partner',
   Organization = 'Organization',
   UserPlan = 'UserPlan',
   User = 'User',
   Board = 'Board'
}

export class GuideDto {
   public id: string;
   public name: string;
   public isEnabled: boolean;
   public createdAt: Date;
   public updatedAt: Date;

   public order: number;

   public createdById: string;
   public createdBy: MinimalUserDto;

   public scope: GuideScope;
   public scopeId?: string;

   public trigger:
      | {
           urlPath?: string;
        }
      | {
           uiClick?: string;
        }
      | {
           analyticsEvent?: string;
        };

   public condition: {
      startAt?: Date;
      endAt?: Date;
      boardUserType?: BoardHubUserType;
      userPlans?: string[];
      userTypes?: UserType[];
      userOrganizationIds?: string[];
      userCreatedAfter?: Date;
      userCreatedBefore?: Date;
   };

   public guideType: string;
   public guideConfig: Record<string, any>;
}

export type GuidePutRequestDto = Pick<GuideDto, 'name' | 'isEnabled' | 'guideConfig' | 'condition' | 'trigger'>;
export type GuideCreateRequestDto = Pick<
   GuideDto,
   'name' | 'isEnabled' | 'scope' | 'scopeId' | 'guideType' | 'guideConfig' | 'condition' | 'trigger'
>;

export class ApiKeyDto {
   public id: string;
   public userId: string;
   public createdAt: Date;
   public name: string;
}

export class ApiKeyCreatedResponseDto {
   public apiKey: ApiKeyDto;
   public rawKey: string;
}

export class ApiKeyCreateRequestDto {
   public name: string;
   public roomId: string;
}

export class ApiKeyUpdateRequestDto {
   public name: string;
}

export class ApiKeysDialogDto {
   roomId: string;
}

export class SignalROptionsDto {
   public maximumMessageSize?: number;
   public keepAliveIntervalSec?: number;
   public timeoutIntervalSec?: number;
   public handshakeTimeoutSec?: number;
   public streamBufferCapacity?: number;
}

export class ReportVerificationDto {
   public isVerified: boolean;
}

export class InteractiveAgentRegistrationDto {
   public name?: string;
   public id: string;
   public agentUserId?: string;
   public shortCode?: string;
   public boardId?: string;
   public boardObjectId?: string;
   public roomUserId?: string;
   public ownedByUserId?: string;
   public expiresAt?: Date;
   public createdAt?: Date;
   public isCompleted?: boolean;
}

export class InteractiveAgentCodeRequestDto {
   public name: string;
}

export class InteractiveAgentRegistrationPatchRequestDto {
   public isExpired?: boolean;
   public expirationDate?: Date;
   public name?: string;
}

export class InteractiveAgentDto {
   public id: string;
   public isOnline?: boolean;
   public clientInfo?: any;
   public createdAt?: Date;
   public lastActivityAt?: Date;
   public lastHeartBeatAt?: Date;
}

export class ThirdPartyConnectionApiPostRequestDto {
   public providerName: string;
   public providerSecret: string;
   public organizationId: string;
   public provider: ThirdPartyAuthProvider;
   public providerConfig: string;
}
