import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
   LoginProviderRequestDto,
   LoginResponseDto,
   ThirdPartyConnectionDisconnectRequestDto,
   ThirdPartyProviderConnectRequestDto
} from '../model/dtos';
import { Role, ThirdPartyAuthProvider } from '../model/enums';
import { ConfigService } from './config.service';
import { IntercomService } from '../core/services/intercom.service';
import Utils from '../utils';

@Injectable({
   providedIn: 'root'
})
export class AuthService {
   private currentUserSubject = new BehaviorSubject<LoginResponseDto>(JSON.parse(localStorage.getItem('currentUser')));
   private adminImpersonationActiveSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('adminImpersonationActive')));

   private headers: HttpHeaders;

   public currentUser = this.currentUserSubject.asObservable();
   public adminImpersonationActive = this.adminImpersonationActiveSubject.asObservable();

   constructor(
      private configService: ConfigService,
      private _router: Router,
      private http: HttpClient,
      private intercomService: IntercomService
   ) {
      this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

      // ensure the restored token is cleared if expired
      if (this.currentUserValue && this.isTokenExpired) this.logout(true);

      this.currentUser.subscribe((user) => {
         if (user) {
            // NOTE: This call wrapped with setTimeout because angular will throw "NG0200: Circular dependency in DI detected for InjectionToken HTTP_INTERCEPTORS" without it
            setTimeout(() => {
               this.configService.fetchAuthenticatedUserSettings();
            });
            this.identifyIntercomUser();
         }
      });
   }

   public get currentUserValue() {
      return this.currentUserSubject.value;
   }

   public get adminImpersonationActiveValue() {
      return this.adminImpersonationActiveSubject.value;
   }

   public connectProvider(provider: ThirdPartyAuthProvider, dto: ThirdPartyProviderConnectRequestDto): Observable<any> {
      const requestUrl = `${this.configService.baseApiUrl}auth/providers/${provider}/connect`;
      return this.http.post<ThirdPartyProviderConnectRequestDto>(requestUrl, dto, { withCredentials: true });
   }

   public disconnectProvider(provider: ThirdPartyAuthProvider, dto: ThirdPartyConnectionDisconnectRequestDto): Observable<any> {
      const requestUrl = `${this.configService.baseApiUrl}auth/providers/${provider}/disconnect`;
      return this.http.post<ThirdPartyProviderConnectRequestDto>(requestUrl, dto);
   }

   public get isTokenExpired(): boolean {
      if (!this.currentUserValue?.authToken?.expiresAt) return true;

      const now = Date.now();
      return now >= new Date(this.currentUserValue.authToken.expiresAt).valueOf();
   }

   public login(email, password): Promise<LoginResponseDto> {
      const requestUrl = `${this.configService.baseApiUrl}auth/login`;
      return this.http
         .post<LoginResponseDto>(requestUrl, { email, password }, { withCredentials: true })
         .pipe(
            map((dto) => {
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               if (!dto.isTwoFactorAuthorizationRequired) this.setLogin(dto);

               return dto;
            })
         )
         .toPromise();
   }

   public loginWithToken(token, userId, rememberDevice) {
      const requestUrl = `${this.configService.baseApiUrl}auth/login-two-factor`;

      return this.http
         .post<LoginResponseDto>(requestUrl, { userId, token, rememberDevice }, { withCredentials: true })
         .pipe(
            map((dto) => {
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               this.setLogin(dto);

               return dto;
            })
         )
         .toPromise();
   }

   public useRecoveryCode(recoveryCode, userId) {
      const requestUrl = `${this.configService.baseApiUrl}auth/tfa-recovery`;

      return this.http.post<LoginResponseDto>(requestUrl, { userId, recoveryCode }, { withCredentials: true }).toPromise();
   }

   public loginWithSSO(): Promise<LoginResponseDto> {
      const requestUrl = `${this.configService.baseApiUrl}auth/sso`;
      return this.http
         .post<LoginResponseDto>(requestUrl, null, { withCredentials: true })
         .pipe(
            map((dto) => {
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem('currentUser', JSON.stringify(dto));
               localStorage.setItem('user', JSON.stringify(dto));
               this.currentUserSubject.next(dto);

               if (this.currentUser && this.decodeRole() === Role.SuperAdmin) {
                  const setFlagOnOriginalOrg = { isActive: false };
                  localStorage.setItem('adminImpersonationActive', JSON.stringify(setFlagOnOriginalOrg));
                  this.adminImpersonationActiveSubject.next(setFlagOnOriginalOrg);
               }

               return dto;
            })
         )
         .toPromise();
   }

   public loginWithProvider(dto: LoginProviderRequestDto): Promise<LoginResponseDto> {
      const requestUrl = `${this.configService.baseApiUrl}auth/login-provider`;
      return this.http
         .post<LoginResponseDto>(requestUrl, dto, { withCredentials: true })
         .pipe(
            map((dto) => {
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem('currentUser', JSON.stringify(dto));
               localStorage.setItem('user', JSON.stringify(dto));
               this.currentUserSubject.next(dto);

               if (this.currentUser && this.decodeRole() === Role.SuperAdmin) {
                  const setFlagOnOriginalOrg = { isActive: false };
                  localStorage.setItem('adminImpersonationActive', JSON.stringify(setFlagOnOriginalOrg));
                  this.adminImpersonationActiveSubject.next(setFlagOnOriginalOrg);
               }

               return dto;
            })
         )
         .toPromise();
   }

   impersonate(organizationId) {
      const requestUrl = `${this.configService.baseApiUrl}auth/impersonate/`;

      return this.http.post<any>(requestUrl, { organizationId }).pipe(
         map((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes

            localStorage.removeItem('currentUser');
            this.currentUserSubject.next(null);

            localStorage.removeItem('adminImpersonationActive');
            this.adminImpersonationActiveSubject.next(null);

            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);

            const setFlagOnOriginalOrg = { isActive: true };
            localStorage.setItem('adminImpersonationActive', JSON.stringify(setFlagOnOriginalOrg));
            this.adminImpersonationActiveSubject.next(setFlagOnOriginalOrg);

            return user;
         })
      );
   }

   cancelImpersonation() {
      // restore the "user" into "currentUser"

      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);

      localStorage.removeItem('adminImpersonationActive');
      this.adminImpersonationActiveSubject.next(null);

      const user = JSON.parse(localStorage.getItem('user'));
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);

      const setFlagOnOriginalOrg = { isActive: false };
      localStorage.setItem('adminImpersonationActive', JSON.stringify(setFlagOnOriginalOrg));
      this.adminImpersonationActiveSubject.next(setFlagOnOriginalOrg);

      return of(user);
   }

   public resetPassword(restinfo: any): Observable<any> {
      return this.http.post(`${this.configService.baseApiUrl}auth/password-reset`, restinfo);
   }

   public forgotpassword(dto: any): Observable<any> {
      return this.http.post(`${this.configService.baseApiUrl}auth/password-forgot`, dto);
   }

   public async logout(withoutNavigation = false) {
      try {
         if (!this.isTokenExpired) await this.http.post(`${this.configService.baseApiUrl}auth/logout`, {}).toPromise();
      } finally {
         // Make sure we delete local resources if logout fails due to token expire
         localStorage.removeItem('currentUser');
         localStorage.removeItem('user');
         localStorage.removeItem('adminImpersonationActive');

         this.currentUserSubject.next(null);
         this.adminImpersonationActiveSubject.next(null);
         this.intercomService.restart();

         if (!withoutNavigation) await this._router.navigate(['auth/login']);
      }
   }

   public resendEmailConfirmation(email: string) {
      return this.http.post(`${this.configService.baseApiUrl}auth/email-resend-confirm`, { email });
   }

   decode() {
      if (this.currentUserValue) {
         return jwtDecode(this.currentUserValue.authToken.token);
      } else {
         return ' ';
      }
   }

   getUserName() {
      if (this.currentUserValue) {
         return this.currentUserValue.user.name;
      } else {
         return '';
      }
   }

   getUserOrg() {
      if (this.currentUserValue && this.currentUserValue.user.organization != null && this.currentUserValue.user.organization.id != null) {
         return this.currentUserValue.user.organization.id;
      } else {
         return null;
      }
   }

   getAdminImpersonationActive() {
      if (this.adminImpersonationActiveValue) {
         return this.adminImpersonationActiveValue.isActive;
      } else {
         return false;
      }
   }

   getUserOrgName() {
      if (
         this.currentUserValue &&
         this.currentUserValue.user.organization != null &&
         this.currentUserValue.user.organization.name != null
      ) {
         return this.currentUserValue.user.organization.name;
      } else {
         return '';
      }
   }

   decodeRole() {
      if (this.currentUserValue) {
         const decoded = this.decode();
         return decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      }
      return null;
   }

   public get isSuperAdmin() {
      return this.decodeRole()?.indexOf(Role.SuperAdmin) > -1;
   }

   public get isOrgAdmin() {
      return this.decodeRole()?.indexOf(Role.OrgAdmin) > -1;
   }

   public get isPartnerAdmin() {
      return this.decodeRole()?.indexOf(Role.PartnerAdmin) > -1;
   }

   public get isInOrg() {
      return !!this.getUserOrg();
   }

   public get isAdminImpersonating() {
      return !!this.adminImpersonationActiveValue?.isActive;
   }

   public get isAuthenticated() {
      return !!this.currentUserValue && !this.isTokenExpired;
   }

   public get token() {
      return this.currentUserValue?.authToken.token;
   }

   private async identifyIntercomUser() {
      try {
         await Utils.waitUntil(() => this.configService.userConfiguration, 100, 60_000);
         await Utils.waitUntil(async () => await this.intercomService.ready(), 100, 60_000);
         await this.intercomService.identifyUser(
            this.currentUserValue.user.email,
            this.currentUserValue.user.name,
            this.configService.userConfiguration?.intercomIdentityVerificationToken
         );
      } catch (err) {
         console.error('Failed to identify user', err);
      }
   }

   private setLogin(dto: LoginResponseDto) {
      localStorage.setItem('currentUser', JSON.stringify(dto));
      localStorage.setItem('user', JSON.stringify(dto));
      this.currentUserSubject.next(dto);

      if (this.currentUser && this.decodeRole() === Role.SuperAdmin) {
         const setFlagOnOriginalOrg = { isActive: false };
         localStorage.setItem('adminImpersonationActive', JSON.stringify(setFlagOnOriginalOrg));
         this.adminImpersonationActiveSubject.next(setFlagOnOriginalOrg);
      }

      if (this.currentUser) {
         this.configService.fetchAuthenticatedUserSettings();
      }
   }

   /*
    saveRefreshToken(refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
    }


    registerUserExternalSignUp() {te

    }

    refresh(jwtToken, refreshToken) {
        return fetch('auth/refresh', {
            method: 'POST',
            body: `token=${encodeURIComponent(jwtToken)}&refreshToken=${encodeURIComponent(this.getRefreshToken())}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }*/
}
