<div fxFlex 
   fxLayout="row" 
   fxLayoutGap.gt-md="20px" 
   fxLayoutGap="10px" 
   fxLayoutAlign="space-between center"
   [class.invalid-license]="!license.isLicenseValid || license.isLicenseExpired"
   >
  
   <div fxFlex fxLayoutAlign="flex-start"> 
      <div>
         @if (impersonatingActive) {
            <button (click)="cancelImpersonation()" color="warn" mat-raised-button>
               Impersonating {{ getOrgName() }}
               <mat-icon>clear</mat-icon>
            </button>
         }
      </div>

      @if (license.licensedLocationName && showLicenseStatus) {
      <div fxFlex>
         <span class="license-location" [class.license-location-left-margin]="impersonatingActive">
            @if (!license.isLicenseValid) {
               <span i18n="@@toolbar.InvalidLicense">Invalid License ({{ license.licensedLocationName }})</span>
            } @else {
               @if (license.isLicenseExpired) {
                  <span i18n="@@toolbar.ExpiredLicense"
                     >Expired license: {{ license.licensedLocationName }}. Please contact Alleo to avoid further disruptions.
                  </span>
               } @else if (isLicenseExpiringSoon) {
                  <span i18n="@@toolbar.ExpiringLicense"
                     >Your {{ license.licensedLocationName }} license expires {{ license.expirationDate | date: 'shortDate'}}. Please contact Alleo to avoid disruptions.
                  </span>
               }
            }
         </span>
      </div>
      }
   </div>

   <div fxFlex></div>

   <div fxFlex fxLayoutAlign="end">
      <button [matMenuTriggerFor]="userMenu" class="user-button" mat-button>
         <mat-icon [class.material-icons-outlined-reversed]="!license.isLicenseValid || license.isLicenseExpired">account_circle</mat-icon>
         <div fxLayout="row" fxLayoutAlign="center center">
            <span fxHide fxShow.gt-sm class="user-name" [class.user-name-invalid-license]="!license.isLicenseValid || license.isLicenseExpired">{{ getUserName() }}</span>
            <mat-icon fxHide.xs [class.material-icons-outlined-reversed]="!license.isLicenseValid || license.isLicenseExpired">keyboard_arrow_down</mat-icon>
         </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
         @if (isSaveReportAvailable()) {
            <button (click)="downloadReport()" mat-menu-item>
               <mat-icon>summarize</mat-icon>
               <span>Save admin report </span>
            </button>
         }

         @if (isSuperAdmin) {
            <button (click)="validateReport()" mat-menu-item>
               <mat-icon>check</mat-icon>
               <span> Verify admin report </span>
            </button>
         }

         <button (click)="onClickLogout()" mat-menu-item>
            <mat-icon>exit_to_app</mat-icon>
            <span> Logout </span>
         </button>
      </mat-menu>
   </div>

</div>