import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LicenseDto } from 'app/model/dtos';
import { OrgService, SnackService } from 'app/services';
import { AuthService } from 'app/services/auth.service';
import { Subject } from 'rxjs';
import Utils from '../../../utils';
import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ConfigService } from '../../../services/config.service';
import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { FlexModule } from '@ngbracket/ngx-layout/flex';

@Component({
   selector: 'app-toolbar',
   templateUrl: './toolbar.component.html',
   styleUrls: ['./toolbar.component.scss'],
   standalone: true,
   imports: [DatePipe, ExtendedModule, FlexModule, MatButton, MatIcon, MatMenu, MatMenuItem, MatMenuTrigger, MatTooltip ]
})
export class ToolbarComponent implements OnInit, OnDestroy {
   // languages: any;
   // selectedLanguage: any;
   userStatusOptions: any[];
   impersonatingActive: boolean;
   currentUserobj;

   private _unsubscribeAll: Subject<void>;

   constructor(
      private authService: AuthService,
      private orgService: OrgService,
      private snackService: SnackService,
      private configService: ConfigService,
      private router: Router
   ) {
      this.userStatusOptions = [
         {
            title: 'Online',
            icon: 'icon-checkbox-marked-circle',
            color: '#4CAF50'
         },
         {
            title: 'Away',
            icon: 'icon-clock',
            color: '#FFC107'
         },
         {
            title: 'Do not Disturb',
            icon: 'icon-minus-circle',
            color: '#F44336'
         },
         {
            title: 'Invisible',
            icon: 'icon-checkbox-blank-circle-outline',
            color: '#BDBDBD'
         },
         {
            title: 'Offline',
            icon: 'icon-checkbox-blank-circle-outline',
            color: '#616161'
         }
      ];

      // this.languages = [
      //    {
      //       id: 'en',
      //       title: 'English',
      //       flag: 'us'
      //    }
      //    /*,
      //       {
      //           id   : 'tr',
      //           title: 'Turkish',
      //           flag : 'tr'
      //       }*/
      // ];

      this._unsubscribeAll = new Subject();
   }

   ngOnInit(): void {
      this.authService.currentUser.subscribe((user) => {
         this.currentUserobj = user;
         this.updateImpersonationFromUpdatedUser();
      });

      this.authService.adminImpersonationActive.subscribe((item) => {
         if (item !== null) {
            this.impersonatingActive = item.isActive;
         }
      });

      // this.selectedLanguage = _.find(this.languages, { id: 'en' });
   }

   updateImpersonation(item) {
      if (item.orginalOrgId !== this.authService.getUserOrg()) {
         this.impersonatingActive = true;
      } else {
         this.impersonatingActive = false;
      }
   }

   updateImpersonationFromUpdatedUser() {}

   redirectToOrganizations(): void {
      this.router.navigate(['pages/org']);
   }

   ngOnDestroy(): void {
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
   }

   getUserName(): string {
      return this.authService.getUserName();
   }

   getOrgName(): string {
      return this.authService.getUserOrgName();
   }  

   isSaveReportAvailable(): boolean {
      return this.authService.getUserOrg() && (this.configService.environment.isOnPremise || this.configService.environment.isDevelopment);
   }

   cancelImpersonation() {
      this.authService.cancelImpersonation().subscribe(
         () => {
            this.snackService.openSuccessSnackBar('Canceled Impersonation');

            this.redirectToOrganizations();
         },
         () => this.snackService.openErrorSnackBar('Failed to Canceled impersonate')
      );
   }

   // search(value): void {}

   // setLanguage(lang): void {
   //    this.selectedLanguage = lang;
   // }

   async onClickLogout() {
      await this.authService.logout();
   }

   async downloadReport() {
      this.snackService.openSuccessSnackBar('Building report');
      const response = (await this.orgService.createAndDownloadAdminReport(this.authService.getUserOrg())) as HttpResponse<any>;
      const blob = response.body;
      const contentDisposition = response.headers.get('content-disposition');
      const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();

      Utils.downloadBlob(blob, filename);
   }

   public async validateReport() {
      const file = (await Utils.selectFile('application/zip', false)) as File;
      this.snackService.openSuccessSnackBar('Processing data...');
      const result = await this.orgService.verifyReportIntegrity(file);
      if (result.isVerified) this.snackService.openSuccessSnackBar('The report was successfully verified');
      else this.snackService.openErrorSnackBar('Report invalid');
   }

   public get isSuperAdmin() {
      return this.authService.isSuperAdmin;
   }

   public get license(): LicenseDto {
      return this.configService.license;
   }

   public get isLicenseExpiringSoon(): boolean {
      return this.configService.licenseExpiresWithinThirtyDays;
   }    

   public get showLicenseStatus(): boolean {
      return !this.license.isLicenseValid || this.license.isLicenseExpired || this.isLicenseExpiringSoon;
   }
   
   // getLanguageFlagUrl(languageFlagName: string) {
   //    return 'assets/icons/flags/' + languageFlagName + '.png';
   // }
}
