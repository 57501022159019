import { ApiResponseErrorCode } from './model/dtos';
import { FileSystemNodeClassifier } from './model/enums';
import { ApiResponseException } from './helpers';
import { get, has, merge, set, uniq, debounce, throttle } from 'lodash';
import { UAParser } from 'ua-parser-js';
import { NotificationService } from './core/services/notification.service';
import { fileTypeFromBuffer } from '@sgtpooki/file-type';
import { format } from 'date-fns';

const fileUploadInput = document.createElement('input');

export class ExternallyResolvedPromise<T> implements Promise<T> {
   private resolver: (T) => void;
   private rejector: (reason: any) => void;

   private resolved = false;

   public get isResolved() {
      return this.resolved;
   }

   private rejected = false;

   public get isRejected() {
      return this.rejected;
   }

   private promise: Promise<T>;

   constructor() {
      this.promise = new (window.__zone_symbol__Promise ?? Promise)((resolve, reject) => {
         this.resolver = resolve;
         this.rejector = reject;
      });
   }

   public resolve(res: T) {
      this.resolver(res);
      this.resolved = true;
   }

   public reject(reason: any) {
      this.rejector(reason);
      this.rejected = true;
   }

   then<TResult1 = T, TResult2 = never>(
      onfulfilled?: (value: T) => TResult1 | PromiseLike<TResult1>,
      onrejected?: (reason: any) => TResult2 | PromiseLike<TResult2>
   ): Promise<TResult1 | TResult2> {
      return this.promise.then(onfulfilled, onrejected);
   }
   catch<TResult = never>(onrejected?: (reason: any) => TResult | PromiseLike<TResult>): Promise<T | TResult> {
      return this.promise.catch(onrejected);
   }

   finally(onfinally?: () => void): Promise<T> {
      return this.promise.finally(onfinally);
   }

   [Symbol.toStringTag]: string;
}

export default class Utils {
   public static throttle = throttle;
   public static debounce = debounce;
   public static uniq = uniq;
   public static get = get;
   public static set = set;
   public static has = has;
   public static merge = merge;
   public static setInterval: typeof window.setInterval = (window.__zone_symbol__setInterval ?? setInterval).bind(window);
   public static clearInterval: typeof window.clearInterval = (window.__zone_symbol__clearInterval ?? clearInterval).bind(window);
   public static setTimeout: typeof window.setTimeout = (window.__zone_symbol__setTimeout ?? setTimeout).bind(window);
   public static clearTimeout: typeof window.clearTimeout = (window.__zone_symbol__clearTimeout ?? clearTimeout).bind(window);
   public static Promise: typeof Promise = window.__zone_symbol__Promise ?? Promise;
   public static ExternallyResolvedPromise = ExternallyResolvedPromise;

   public static camelCase(str: string): string {
      return str.substring(0, 1).toLowerCase() + str.substring(1);
   }

   public static MimeTypeMapping = {
      Audio: ['audio/ogg', 'audio/mpeg', 'audio/mp3'],
      Video: ['video/webm', 'video/ogg', 'video/mp4'],
      Document: [
         'application/pdf',
         'application/vnd.openxmlformats-officedocument.presentationml.presentation',
         'application/vnd.ms-powerpoint',
         'application/msword',
         'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
         'application/vnd.ms-excel',
         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ],
      Image: ['image/gif', 'image/jpeg', 'image/png', 'image/webp', 'image/x-icon', 'image/svg+xml'],
      Model: [
         'model/vnd.gltf',
         'model/vnd.gltf+json',
         'model/vnd.gltf-json',
         'model/vnd.gltf-binary',
         'model/gltf-binary',
         'model/gltf+json',
         'model/stl',
         'model/x.stl-ascii',
         'model/x.stl-binary',
         'application/prs.wavefront-obj'
      ]
   };

   public static ExtensionTypeMapping = {
      Model: ['stl', 'gltf', 'glb', 'obj'],
      Document: ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'],
      Image: ['gif', 'jpeg', 'jpg', 'png', 'webp'],
      Video: ['webm', 'mp4'],
      Audio: ['mp3', 'ogg']
   };

   public static isApiErrorCode(err: any, code: ApiResponseErrorCode, subCode: any = null): boolean {
      const apiErr = err as ApiResponseException;

      if (!apiErr?.errorCode) return false;

      if (subCode) return apiErr.errorCode === code && apiErr.errorSubCode === subCode;

      return apiErr.errorCode === code;
   }

   public static bytesToGbStr(value: number, precision: number = 2) {
      return (value / 1024 / 1024 / 1024).toFixed(precision);
   }

   public static extractGuid(str: string): string {
      const matches = str.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i);
      return matches ? matches[0] : null;
   }

   public static selectFile(allowedMimetypes: string = null, multiple = false): Promise<File | File[] | false> {
      return new this.Promise((resolve, reject) => {
         fileUploadInput.type = 'file';
         fileUploadInput.multiple = multiple;

         if (allowedMimetypes) fileUploadInput.accept = allowedMimetypes;
         else fileUploadInput.accept = null;

         fileUploadInput.onchange = () => {
            const files = fileUploadInput.files as FileList;

            if (files && files.length === 0) reject();

            if (!multiple) resolve(files.item(0));
            else resolve(Array.from(files));

            return false;
         };

         // https://stackoverflow.com/questions/38845015/inputtype-file-change-event-are-lost-occasionally-on-chrome
         this.setTimeout(function () {
            fileUploadInput.value = null;
            fileUploadInput.click();
         }, 0);
      });
   }

   public static isMobile() {
      return Utils.isDevice('mobile');
   }

   private static isDevice(deviceType: string) {
      const deviceData = this.getDeviceData();
      return deviceData.device != null ? deviceData.device.type === deviceType : false;
   }

   public static getDeviceData() {
      const parser = new UAParser();
      return parser.getResult();
   }

   public static async getMimetype(file: File) {
      const ft = await fileTypeFromBuffer(await file.slice(0, 5000).arrayBuffer());
      if (!ft) return 'application/octet-stream';
      return ft.mime || 'application/octet-stream';
   }

   public static getFileType(mimetype: string, ext: string = null): FileSystemNodeClassifier {
      for (const [type, mimeList] of Object.entries(Utils.MimeTypeMapping)) {
         if (mimeList.includes(mimetype)) {
            return FileSystemNodeClassifier[type];
         }
      }

      if (ext && (mimetype === 'application/octet-stream' || mimetype === 'text/plain')) return Utils.getFileTypeFromExtension(ext);

      return null;
   }

   public static getFileTypeFromExtension(ext): FileSystemNodeClassifier {
      for (const [type, extList] of Object.entries(Utils.ExtensionTypeMapping)) {
         if (extList.includes(ext)) {
            return FileSystemNodeClassifier[type];
         }
      }

      return null;
   }

   public static isUriComponentEncoded(uri: string): boolean {
      uri = uri || '';
      return uri !== decodeURIComponent(uri);
   }

   public static objectToQueryString(a, useArrayIndex = false, useArrayBrackets = true) {
      const s = [];
      const add = function (k, v) {
         v = typeof v === 'function' ? v() : v;
         v = v === null ? '' : v === undefined ? '' : v;
         if (v instanceof Date) v = v.toISOString();
         s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
      };
      const buildParams = function (prefix, obj) {
         let i;
         let len;
         let key;

         if (!obj) return s;

         if (prefix) {
            if (Array.isArray(obj)) {
               for (i = 0, len = obj.length; i < len; i++) {
                  buildParams(
                     prefix + (useArrayBrackets ? '[' + (typeof obj[i] === 'object' && obj[i] ? i : useArrayIndex ? i : '') + ']' : ''),
                     obj[i]
                  );
               }
            } else if (String(obj) === '[object Object]') {
               for (const [k, v] of Object.entries(obj)) {
                  buildParams(prefix + '.' + k, v);
               }
            } else {
               add(prefix, obj);
            }
         } else if (Array.isArray(obj)) {
            for (i = 0, len = obj.length; i < len; i++) {
               add(obj[i].name, obj[i].value);
            }
         } else {
            for (const [k, v] of Object.entries(obj)) {
               buildParams(k, v);
            }
         }
         return s;
      };

      return buildParams('', a).join('&');
   }

   /** the condition function result is checked for truthiness if a single value is returned
    *  and for values being non-null in case it returns an array
    */
   public static waitUntil(condition: () => any | Array<any>, checkIntervalMs = 10, timeoutMs: number = null) {
      return new this.Promise<ReturnType<typeof condition>>((resolve, reject) => {
         const timeoutTime = timeoutMs ? new Date().getTime() + timeoutMs : null;
         const interval = this.setInterval(() => {
            const c = condition();
            if (c && (!(c instanceof Array) || c.reduce((p, t) => p !== null && p !== undefined && t, true))) {
               resolve(c);
               this.clearInterval(interval);
            } else if (timeoutTime && new Date().getTime() > timeoutTime) {
               reject(new Error('waitUntil timeout'));
               this.clearInterval(interval);
            }
         }, checkIntervalMs);
      });
   }

   public static async writeClipboardText(text: string, successNotification: string = 'Copied to clipboard') {
      try {
         await navigator.clipboard.writeText(text);
         if (successNotification) NotificationService.instance?.info(successNotification);
         return true;
      } catch (error) {
         return false;
      }
   }

   public static sleep(ms?) {
      return new this.Promise((resolve) => this.setTimeout(resolve, ms));
   }

   private static downloadQueue: (() => Promise<void>)[] = [];

   public static async downloadFile(url: string, filename: string = null) {
      const q = this.downloadQueue;

      q.push(async () => {
         await this.doDownloadFile(url, filename);

         q.shift();

         if (q.length) q[0]();
      });

      if (q.length === 1) q[0]();
   }

   public static async doDownloadFile(url: string, filename: string = null) {
      const a = document.createElement('a');
      a.href = url;

      const urlObj = new URL(url);
      const pathSegments = urlObj.pathname.split('/');
      const lastPathSegment = pathSegments[pathSegments.length - 1];

      a.download = filename ?? lastPathSegment;

      const div = document.createElement('div');
      div.appendChild(a);

      const iframe = document.createElement('iframe');
      iframe.style.visibility = 'none';
      iframe.style.opacity = '0';
      iframe.classList.add('download-iframe');
      /* 'allow-downloads-without-user-activation',  */
      iframe.sandbox.add('allow-downloads', 'allow-same-origin', 'allow-scripts');

      document.body.appendChild(iframe);

      iframe.srcdoc = `<html><body>${div.innerHTML}<script>document.querySelector('a').click();</script></body><html>`;
      await Utils.sleep(2000);

      document.body.removeChild(iframe);
   }

   public static dataURItoBlob(dataURI: string): Blob {
      const tmp = dataURI.split(',');
      const meta = tmp.shift();
      let byteString = tmp.join(',');

      if (meta.indexOf('base64') !== -1) byteString = atob(byteString);

      // separate out the mime component
      const mimeString = meta.split(':')[1].split(';')[0];

      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
         ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
   }

   public static downloadBlob(blob: Blob, filename: string) {
      Utils.downloadFile(URL.createObjectURL(blob), filename);
   }

   public static dateToFormattedString(date: Date): string {
      // corresponds to dayjs format('L LT')
      return format(date, 'P p');
   }

   public static getCurrentUtc(): Date {
      const currentLocal = new Date();
      return new Date(
         Date.UTC(
            currentLocal.getUTCFullYear(),
            currentLocal.getUTCMonth(),
            currentLocal.getUTCDate(),
            currentLocal.getUTCHours(),
            currentLocal.getUTCMinutes(),
            currentLocal.getUTCSeconds(),
            currentLocal.getUTCMilliseconds()
         )
      );
   }
}
